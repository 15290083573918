import React, { Component } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Moment from 'moment';

class Scholarship extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataScholarship:[],
            dataJenjang: [],
            dataNegara: [],
            dataTipeDana: [],
            dataUnviersities: [],
            selectedFilterJenjang: [],
            modal: false,
            description: "",
            university: [],
            country: [],
            tipe_finance: [],
            program : [],
        }
    }

    componentDidMount() {
        this.getScholarship();
        this.getFilterJenjang();
        this.getFilterNegara();
        this.getFilterTipeDana();
        this.getFilterUniversities();
    }

    getScholarship() {
        axios.get(API_URL.superStudent+'/website/scholarship', {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataScholarship: result })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getFilterJenjang() {
        axios.get(API_URL.superStudent+'/filter/jenjang', {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataJenjang: result })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getFilterNegara() {
        axios.get(API_URL.superStudent+'/filter/negara', {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataNegara: result })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getFilterTipeDana() {
        axios.get(API_URL.superStudent+'/filter/tipe', {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataTipeDana: result })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getFilterUniversities() {
        axios.get(API_URL.superStudent+'/filter/universities', {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataUnviersities: result })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getScholarshipByFilter() {
        var formData = new FormData();
        formData.append('university', JSON.stringify(this.state.university));
        formData.append('country', JSON.stringify(this.state.country));
        formData.append('tipe', JSON.stringify(this.state.tipe_finance));
        formData.append('program', this.state.program);

        axios.post(API_URL.superStudent+'/filter/scholarship', formData, {
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataScholarship: result })
        })
        .catch(error => {
            console.log(error)
        })
    }

    closeDialog() {
        this.setState({
            modal: false
        })
    }

    detailDesc(e) {
        this.setState({
            modal: true,
            description: e
        })
    }

    modalDetail() {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>Description</ModalHeader>
                    <ModalBody>
                        <p>{this.state.description}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    filterUniversity(e) {
        let checked = e.target.checked;

        if (checked) {
            this.setState(prevState => ({
                university: [...prevState.university, e.target.value]
            }), () => {
                this.getScholarshipByFilter();
            })
        } else {
            let university = [...this.state.university];
            university.splice(e.target.value, 1);
            this.setState({ 
                university 
            }, () => {
                this.getScholarshipByFilter();
            })
        }
    }

    filterCountry(e) {
        let checked = e.target.checked;

        if (checked) {
            this.setState(prevState => ({
                country: [...prevState.country, e.target.value]
            }), () => {
                this.getScholarshipByFilter();
            })
        } else {
            let country = [...this.state.country];
            country.splice(e.target.value, 1);
            this.setState({ 
                country 
            }, () => {
                this.getScholarshipByFilter();
            })
        }
    }

    filterTipeFinance(e) {
        let checked = e.target.checked;

        if (checked) {
            this.setState(prevState => ({
                tipe_finance: [...prevState.tipe_finance, e.target.value]
            }), () => {
                this.getScholarshipByFilter();
            })
        } else {
            let tipe_finance = [...this.state.tipe_finance];
            tipe_finance.splice(e.target.value, 1);
            this.setState({ 
                tipe_finance 
            }, () => {
                this.getScholarshipByFilter();
            })
        }
    }

    filterProgram(e) {
        let checked = e.target.checked;

        if (checked) {
            this.setState(prevState => ({
                program: [...prevState.program, e.target.value]
            }), () => {
                this.getScholarshipByFilter();
            })
        } else {
            let program = [...this.state.program];
            program.splice(e.target.value, 1);
            this.setState({ 
                program 
            }, () => {
                this.getScholarshipByFilter();
            })
        }
    }
    
    render() {

        return (
            <div>
                <section className='bg-default'>
                    <div className='row'>
                        <img className='img-fluid rounded float-right' src="../../../assets/img/slide/scholarship/scholarship.jpg"></img>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <Row className="justify-content-center">
                            <Col lg='3' md='6' sm='12' className='mb-5 mr-5'>
                                <Accordion allowZeroExpanded>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                University
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            {
                                                this.state.dataUnviersities.map((detail, i) => {
                                                    return (
                                                        <div>
                                                            <input 
                                                                type="checkbox" 
                                                                name='university' 
                                                                value={detail.code_school_rep} 
                                                                onChange={(e) => this.filterUniversity(e)} />
                                                            <label style={{fontSize: '15px'}}>&nbsp;&nbsp;{detail.universities != null ? detail.universities.nama_sekolah : 'Others'}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Negara
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            {
                                                this.state.dataNegara.map((detail, i) => {
                                                    return (
                                                        <div>
                                                            <input type="checkbox" 
                                                                    name='country' 
                                                                    value={detail.country.countrycode}
                                                                    onChange={(e) => this.filterCountry(e)} />
                                                            <label>&nbsp;&nbsp;{detail.country.countryname}</label><br/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Tipe Pendanaan
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            {
                                                this.state.dataTipeDana.map((detail, i) => {
                                                    return (
                                                        <div>
                                                            <input 
                                                                type="checkbox" 
                                                                name='tipe_finance'
                                                                value={detail.scholarship_type} 
                                                                onChange={(e) => this.filterTipeFinance(e)} />
                                                                {
                                                                    detail.scholarship_type == 1 ? 
                                                                    <label>&nbsp;&nbsp;Fully Funded</label>
                                                                    :
                                                                    <label>&nbsp;&nbsp;Partially Funded</label>
                                                                }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Program
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <input type="checkbox" name='program' value='Cerviticate 3' onChange={(e) => this.filterProgram(e)} /><label>&nbsp;&nbsp;Cerviticate 3</label>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <input type="checkbox" name='program' value='Cerviticate 4' onChange={(e) => this.filterProgram(e)} /><label>&nbsp;&nbsp;Cerviticate 4</label>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12'> 
                                                    <input type="checkbox" name='program' value='Diploma' onChange={(e) => this.filterProgram(e)} /><label>&nbsp;&nbsp;Diploma</label>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12'> 
                                                    <input type="checkbox" name='program' value='Advanced Diploma' onChange={(e) => this.filterProgram(e)} /><label>&nbsp;&nbsp;Advanced Diploma</label>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <input type="checkbox" name='program' value='Bachelor' onChange={(e) => this.filterProgram(e)} /><label>&nbsp;&nbsp;Bachelor</label>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <input type="checkbox" name='program' value='Master' onChange={(e) => this.filterProgram(e)} /><label>&nbsp;&nbsp;Master</label>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <input type="checkbox" name='program' value='Ph.D' onChange={(e) => this.filterProgram(e)} /><label>&nbsp;&nbsp;Ph.D</label>
                                                </div>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </Col>
                            <Col lg='9' md='6' sm='12' className='mb-5 mr-5' style={{paddingLeft: '40px'}}>
                                <Row>
                                    {
                                        this.state.dataScholarship.map((detail, i) => (
                                            <Col lg='6'>
                                                <Row className='card-promo-campus-base-top'>
                                                    <Col lg='6' md='6' sm='6' xs='6'>
                                                        <h5><strong>{detail.country.countryname}</strong></h5>
                                                    </Col>
                                                </Row>
                                                <Row className='text-start card-promo-campus-base-middle'>
                                                    <Col lg='12' md='12' sm='12' xs='12'>
                                                        { 
                                                            detail.scholarship_type == 1 ? 
                                                            <h6><strong>Fully Funded</strong></h6>
                                                            :
                                                            <h6><strong>Partially Funded</strong></h6>
                                                        }
                                                        <h5><strong>{detail.scholarship_name}</strong></h5>
                                                    </Col>
                                                    <Col lg='12' md='12' sm='12' xs='12' className='mt-5 mb-5'>
                                                        {
                                                            detail.program != null ? 
                                                            detail.program.map((program) => (
                                                                <h6><strong>{program.program}</strong></h6>
                                                            ))
                                                            :
                                                            ''
                                                        }
                                                    </Col>
                                                    <h5 className='mt-3 mb-3'><strong>{detail.universities != null ? detail.universities.nama_sekolah : ''}</strong></h5>
                                                </Row>
                                                <Row className='text-start card-promo-campus-base-bottom'>
                                                    <Col lg='12' md='12' sm='12' xs='12'>
                                                        <Row>
                                                            <Col lg='1' md='1' sm='1' xs='1' className='text-center'>
                                                                <h5><i class="bi bi-calendar-check"></i></h5>
                                                            </Col>
                                                            <Col lg='9' md='9' sm='9' xs='9' mb='3'>
                                                                <h6><strong>Open <span>:</span> {Moment(detail.open_reg).local().format("DD-MMM-YYYY")}</strong></h6>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg='1' md='1' sm='1' xs='1' className='text-center'>
                                                                <h5><i class="bi bi-calendar-check"></i></h5>
                                                            </Col>
                                                            <Col lg='9' md='9' sm='9' xs='9' mb='3'>
                                                                <h6><strong>Deadline <span>:</span> {Moment(detail.close_reg).local().format("DD-MMM-YYYY")}</strong></h6>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </section>
                {this.modalDetail()}
            </div>
        )

    }

}

export default (Scholarship);