import React, { useEffect } from 'react';

const IASBadge = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.src = "https://www-cdn.icef.com/scripts/iasbadgeid.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <span id="iasBadge" data-account-id="4611"></span>
  );
};

export default IASBadge;